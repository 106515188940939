<template>
    <div class="promo-solar">
        <div class="logo-container">
            <img class="logo" src="@/assets/logo.svg" alt="Лого" /> <!-- Путь к вашему SVG лого -->
        </div>
        <div class="loading-dots">
            <div></div><div></div><div></div>
        </div>
        <PromoFooter />
    </div>
</template>

<script>
import PromoFooter from './PromoFooter.vue'

export default {
    name: 'PromoSolar',
    components: {
        PromoFooter
    }
}
</script>

<style>
@keyframes bounce {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1.0);
    }
}

html, body, #app, .promo-solar {
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.logo-container {
    z-index: 2;
}

.logo {
    width: 50vw;
    height: auto;
    max-width: 600px;
}

.loading-dots {
    position: absolute;
    top: 60%;
    width: 100%;
    text-align: center;
}

.loading-dots div {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0 3px;
    background-color: white;
    border-radius: 50%;
    animation: bounce 1.4s infinite ease-in-out both;
}

.loading-dots div:nth-child(1) { animation-delay: -0.32s; }
.loading-dots div:nth-child(2) { animation-delay: -0.16s; }
.loading-dots div:nth-child(3) { animation-delay: 0; }

@media (min-width: 320px) {
    .logo {
        width: 320px;
    }
    .loading-dots div {
        width: 15px;
        height: 15px;
    }
    
}

@media (min-width: 576px) {
    .logo {
        width: 400px;
    }
    .loading-dots div {
        width: 18px;
        height: 18px;
    }
}

@media (min-width: 768px) {
    .logo {
        width: 500px;
    }
    .loading-dots div {
        width: 20px;
        height: 20px;
    }
}

@media (min-width: 992px) {
    .logo {
        width: 700px;
    }
    .loading-dots div {
        width: 25px;
        height: 25px;
    }
}
</style>
